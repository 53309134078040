<template :key="completedAmount">
  <UIModal :show-modal="true">
    <template v-slot:header>
      <h6
        class="modal-title tw-mx-auto tw-font-bold tw-text-grey34 dark:tw-text-greye9"
      >
        {{ $t('common.modals.acceptToProceed') }}
      </h6>

      <Transition name="slide-fade" mode="out-in">
        <div :key="completedAmount">
          {{ completedAmount }}
        </div>
      </Transition>
    </template>

    <template v-if="currentConsent" v-slot:default>
      <Transition name="slide-fade" mode="out-in">
        <div>
          <p
            :key="currentConsent.type.name"
            class="tw-text-md tw-text-center tw-font-bold"
          >
            {{ currentConsent.type.name }}
          </p>

          <p
            :key="currentConsent.description"
            class="tw-text-md tw-text-justify"
          >
            {{ currentConsent.description }}
          </p>

          <div>
            <p>{{ $t('views.consents.filesAttached') }}</p>
            <FilePreview
              v-if="consentFiles.length > 0"
              :compact="true"
              :can-delete="false"
              :files="consentFiles"
            />
          </div>
        </div>
      </Transition>
    </template>

    <template v-slot:footer>
      <UIButton
        type="secondary"
        class="!tw-mr-auto !tw-max-w-max"
        :label="$t('common.sessionTimeout.buttons.logout')"
        size="medium"
        @click="logout"
        button-class="tw-text-black"
      />

      <UIButton
        type="primary"
        :label="$t('common.modals.accept')"
        size="medium"
        button-class="tw-text-black"
        @click="handleAccept"
      />
    </template>
  </UIModal>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import UIModal from '@/components/UI/UIModal.vue'
import UIButton from '@/components/UI/UIButton.vue'
import { consentsModalService, fileService } from '@/main'
import { userService } from '@/main'
import type { IConsent } from '@/types/ConsentsTypes'
import type { Nullable } from '@/types/Nullable'
import { routeNames } from '@/router/types'
import type { IFilePreview } from '@/types/FileUploadTypes'
import UICombobox from '@/components/UI/UICombobox.vue'
import FilePreview from '@/components/UI/FilePreview.vue'

@Component({
  components: { FilePreview, UICombobox, UIModal, UIButton },
})
export default class GlobalModalController extends Vue {
  public consentFiles: IFilePreview[] = []

  public get currentConsent(): Nullable<IConsent> {
    return consentsModalService.getUnacceptedConsents?.[0] ?? null
  }

  public async getConsentFiles(): Promise<void> {
    if (!this.currentConsent) return
    this.consentFiles = await fileService.getFiles(
      'consent',
      this.currentConsent.id
    )
  }
  @Watch('currentConsent', { deep: true })
  public async getFilesForCurrentConsent(): Promise<void> {
    await this.getConsentFiles()
  }

  public mounted(): void {
    this.getConsentFiles()
  }

  public get completedAmount(): string {
    return consentsModalService.getCompletedConsents
  }

  public async handleAccept(): Promise<void> {
    if (!this.currentConsent) return
    await consentsModalService.accept(
      this.currentConsent.id,
      this.currentConsent.type.id
    )
  }

  public logout(): void {
    userService.logout()
    this.$router.push({ name: routeNames.Logout })
  }
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
