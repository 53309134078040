import { Store, Pinia } from 'pinia-class-component'
import type {
  IConsent,
  IConsentCompany,
  IConsentSendForm,
} from '@/types/ConsentsTypes'
import ConsentRepository from '@/repositories/ConsentRepository'
import type { Nullable } from '@/types/Nullable'
// import FilesRepository from '@/repositories/FilesRepository'

interface IConsentStore {
  consents: IConsent[]
  consent: Nullable<IConsent>
  companies: IConsentCompany[]
  isLoading: boolean
  isError: boolean
}

@Store
export default class ConsentService extends Pinia {
  public store: IConsentStore = {
    consents: [],
    consent: null,
    companies: [],
    isLoading: false,
    isError: false,
  }

  public get consent(): Nullable<IConsent> {
    return this.store.consent
  }

  public get consents(): IConsent[] {
    return this.store.consents
  }

  public get companies(): IConsentCompany[] {
    return this.store.companies
  }

  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public async loadConsents(): Promise<void> {
    this.store.isLoading = true
    await ConsentRepository.getConsents()
      .then((response) => {
        this.store.consents = response
      })
      .catch(() => {
        this.store.consents = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadConsent(id: number | string): Promise<void> {
    this.store.isLoading = true
    await ConsentRepository.getConsent(id)
      .then((response) => {
        this.store.consent = response
      })
      .catch((e) => {
        this.store.consent = null
        this.store.isError = true
        throw e
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadCompanies(): Promise<void> {
    this.store.isLoading = true
    await ConsentRepository.getCompanies()
      .then((response) => {
        this.store.companies = response
      })
      .catch(() => {
        this.store.companies = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addConsent(consent: IConsentSendForm): Promise<IConsent | null> {
    this.store.isLoading = true

    return await ConsentRepository.addConsent(consent)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        this.store.isError = true
        throw error
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async updateConsent(consent: IConsentSendForm): Promise<void> {
    this.store.isLoading = true

    await ConsentRepository.updateConsent(consent)
      .then((response) => {
        this.store.consent = response
        this.store.isError = false
      })
      .catch((e) => {
        this.store.isError = true
        throw e
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async deleteConsent(id: number): Promise<void> {
    this.store.isLoading = true
    await ConsentRepository.deleteConsent(id)
      .catch(() => {
        this.store.companies = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addConsentFile(file: File, id: number): Promise<void> {
    this.store.isLoading = true
    const formData = new FormData()
    formData.append('file', file)
    formData.append('scopeType', 'consent')
    formData.append('scopeId', String(id))
    // await FilesRepository.uploadFile(formData)
    //   .catch((error) => {
    //     console.error(error.message)
    //     this.store.isError = true
    //   })
    //   .finally(() => {
    //     this.store.isLoading = false
    //   })
  }
}
