import type { ObjectKeys } from '@/types/HelperTypes'

export const routeNames = {
  Dashboard: 'dashboard',
  Tasks: 'tasks',
  TasksNew: 'tasksNew',
  Assets: 'assets',
  AssetsNew: 'assetNew',
  AssetsEdit: 'assetEdit',
  Users: 'users',
  UserPermissions: 'usersPermissions',
  Glossaries: 'glossaries',
  GlossariesFormEdit: 'glossariesFormEdit',
  GlossariesEdit: 'glossariesEdit',
  Companies: 'companies',
  CompaniesNew: 'companiesNew',
  CompaniesEdit: 'companiesEdit',
  Attributes: 'attributes',
  AttributesEdit: 'attributesEdit',
  AttributesNew: 'attributesNew',
  Parameters: 'parameters',
  Roles: 'roles',
  RoleEdit: 'roleEdit',
  Consents: 'consents',
  ConsentsNew: 'consentsNew',
  ConsentsEdit: 'consentsEdit',
  ConsentDetails: 'ConsentDetails',
  ProfileSettings: 'profileSettings',
  Calendar: 'calendar',
  Login: 'login',
  Login2FA: 'login2FA',
  Logout: 'logout',
  Register: 'register',
  RegisterSelect: 'registerSelect',
  ResetPassword: 'resetPassword',
  ResetPasswordConfirmation: 'resetPasswordConfirmation',
  Risks: 'risks',
  Affairs: 'affairs',
  AffairsNew: 'affairsNew',
  AffairsEdit: 'affairsEdit',
  RiskPreview: 'RiskPreview',
  NotFound: 'notFound',
  Tickets: 'tickets',
  TicketsNew: 'ticketsNew',
  TicketsAnonymous: 'ticketsAnonymous',
  Verify: 'verify',
  Identification: 'identification',
  IdentificationNew: 'identificationNew',
  IdentificationEdit: 'identificationEdit',
  Sources: 'sources',
  SourcesNew: 'sourcesNew',
  SourcesEdit: 'sourcesEdit',
  NotificationsSettings: 'notificationsSettings',
  PreviewTicket: 'previewTicket',
  TermsOfService: 'termsOfService',
  PrivacyPolicy: 'privacyPolicy',
  Reports: 'reports',
} as const

export type RouteNamesTypes = ObjectKeys<typeof routeNames>
