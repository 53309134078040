export default {
  attributes: {
    attributes: 'Atrybuty',
    newAttributes: 'Nowy atrybut',
    editAttributes: 'Edycja atrybutu',
    activeAttribute: 'Czy atrybut aktywny?',
    name: 'Nazwa',
    yes: 'Tak',
    no: 'Nie',
    active: 'Aktywny',
    inactive: 'Nieaktywny',
    notifications: {
      added: 'Atrybut został dodany',
      changed: 'Atrybut został zmieniony',
    },
    info: {
      para1_1: 'Funkcjonalność',
      para1_2: 'Atrybuty',
      para1_3:
        'w systemie SYMBIONIS umożliwia dodawanie oraz zarządzanie informacjami charakterystycznymi dla zgłoszeń, zdarzeń czy spraw, które są wprowadzane przez użytkowników systemu. Atrybutami mogą być takie dane jak:',
      ip: 'Adres IP',
      email: 'Adres e-mail',
      name: 'Imię',
      surname: 'Nazwisko',
      else: 'Inne dane wprowadzane przez użytkowników podczas zgłaszania spraw lub zdarzeń.',
      para2:
        'Dzięki tej funkcji osoby analizujące zgłoszenia mają dostęp do syntetycznej informacji, co ułatwia analizę i monitorowanie powiązań pomiędzy różnymi zgłoszeniami. Atrybuty te mogą być wykorzystywane do korelacji zgłoszeń lub zadań, jeśli wystąpią te same lub podobne wartości atrybutów w różnych zgłoszeniach.',
      keyFunctionality: 'Kluczowe funkcjonalności:',
      newAttributes: 'Dodawanie nowych atrybutów',
      newAttributesPara:
        'Administratorzy mogą definiować nowe atrybuty, które będą wykorzystywane do wprowadzania i kategoryzowania danych w systemie. Dzięki temu możliwe jest zbieranie dodatkowych informacji od użytkowników, co zwiększa precyzję analizy.',
      connections: 'Powiązania zgłoszeń po atrybutach:',
      connectionsPara:
        'System umożliwia ręczne oraz w przyszłości automatyczne korelowanie zgłoszeń i zdarzeń na podstawie zdefiniowanych atrybutów. Przykładowo, jeśli w dwóch różnych zgłoszeniach pojawi się ten sam adres e-mail, administrator lub osoba analizująca sprawy może łatwiej połączyć te zgłoszenia i przeprowadzić pełniejszą analizę.',
      automaticConnection:
        'Automatyczne łączenie spraw (funkcjonalność w trakcie wdrażania):',
      automaticConnectionPara:
        'W przyszłości system będzie automatycznie identyfikował zgłoszenia, które zawierają te same atrybuty, i proponował ich połączenie. Dzięki temu administratorzy będą mogli szybciej i sprawniej analizować sprawy, które mogą być ze sobą powiązane, co znacząco usprawni zarządzanie dużymi ilościami danych.',
      dataExport: 'Eksport danych:',
      dataExportPara:
        'umożliwia eksportowanie zgromadzonych danych do plików CSV, co ułatwia dalszą analizę poza systemem oraz integrację z innymi narzędziami analitycznymi.',
      attributesManagement: 'Zarządzanie atrybutami:',
      attributesManagementPara:
        'Administratorzy mogą zarządzać istniejącymi atrybutami, modyfikować je oraz usuwać te, które są nieaktualne lub niepotrzebne.',
      examples: 'Przykład zastosowania:',
      examplesPara:
        ' Jeśli użytkownik systemu zgłasza zdarzenie, w którym podaje swoje imię, nazwisko oraz adres e-mail, informacje te są automatycznie przypisywane do zgłoszenia jako atrybuty. W przypadku powiązania tego samego adresu e-mail z innym zgłoszeniem, osoba analizująca zgłoszenie może łatwo zidentyfikować potencjalne korelacje, co przyczynia się do pełniejszej analizy i efektywnego rozwiązywania spraw.',
      endingNote:
        ' Wprowadzenie i zarządzanie atrybutami w systemie pozwala na precyzyjną organizację i szybsze reagowanie na zgłoszenia, szczególnie w sytuacjach wymagających śledzenia wielu powiązań pomiędzy różnymi sprawami.',
    },
  },
}
